import { PropsWithChildren, createContext, useState } from 'react';

type Props = {} & PropsWithChildren

export type BigModalTypes = 'faq' | 'privacy' | 'terms'

export const BigModalContext = createContext<
    {
        modalProps: { isOpened: boolean, modalType: BigModalTypes; faqId?: string; };
        setIsModalOpened: (value: boolean, type?: BigModalTypes, faqId?: string) => void;
    }
>({
    modalProps: {
        isOpened: false,
        modalType: 'faq',
    },
    setIsModalOpened: () => { },
});

export const BigModalProvider: React.FC<Props> = ({ children }) => {
    const [isOpened, setIsOpened] = useState(false);
    const [modalType, setModalType] = useState<BigModalTypes>('faq');
    const [faqId, setFaqId] = useState<string>();

    const onSetModalOpen = (value: boolean, type?: BigModalTypes, newFaqId?: string) => {
        setIsOpened(value);

        if (type) {
            setModalType(type);
        }

        if (newFaqId) {
            setFaqId(newFaqId);
        }
    }

    return (
        <BigModalContext.Provider value={{ modalProps: { isOpened, modalType, faqId }, setIsModalOpened: onSetModalOpen }}>
            {children}
        </BigModalContext.Provider>
    );
};