import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes as Router } from 'react-router-dom';
import { Routes } from './common-constants/routes';
import { BigModalProvider } from './components/providers/big-modal-provider';

const Blog = lazy(() => import('./pages/blog'));
const Article = lazy(() => import('./pages/article'));
const SteamReplenishment = lazy(() => import('./pages/steam-replenishment'));


const App = () => {
  return (
    <BigModalProvider>
      <Suspense fallback={<></>}>
        <Router>
          <Route path={Routes.Blog} element={<Blog />} />
          <Route path={`${Routes.Article}/:id`} element={<Article />} />
          <Route path={Routes.Home} element={<SteamReplenishment />} />

          <Route path="*" element={<Navigate to={Routes.Home} replace />} />
        </Router>
      </Suspense>
    </BigModalProvider>
  );
};

export default App;
